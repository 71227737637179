import React, { useState, useRef, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useTheme } from "../ThemeContext.js";
import { Dropdown, Image, Card, Accordion } from "react-bootstrap";

//import api
import { getHedgingList } from "../../api/tradeAction.js";

//import lib
import { toFixed } from "../../lib/roundOf.js";

export default function PortfolioPerformance(props) {
  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useTheme();

  //props
  const { piecurrency } = props;

  //state
  const [chartdata, setchartdata] = useState([]);
  const [chartDate, setchartDate] = useState([]);
  const [filterType, setFilterType] = useState("day"); // Initial filter type
  const [pairId, setpairId] = useState(""); // Initial filter type

  const chartOptions = {
    chart: {
      foreColor: theme === "dark" ? "#fff" : "#000",
      toolbar: {
        show: false,
      },
      height: 300,
      type: "area",
      id: "area-chart",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    tooltip: {
      theme: theme,
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "category",
      categories: chartDate,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return toFixed(value, 4); // Formats to 4 decimal places
        },
      },
    },
    // colors: chartdata.map((series) => series.color),
    // markers: {
    //   colors: chartdata.map((series) => series.color),
    // }
  };

  const fetchData = async () => {
    try {
      let data = {
        filter: filterType,
        pairId: pairId?._id,
      };
      const { status, result } = await getHedgingList(data);
      if (status === "success") {
        let seriesData = {};
        let dateArray = [];

        result.forEach((item) => {
          const currency = item.firstCurrency;
          // const colorObj = piecurrency && piecurrency.length > 0 && piecurrency.find((el) => el.currency === currency);
          // const color = colorObj ? colorObj.colorCode : '#36A2EB';

          if (!seriesData[currency]) {
            seriesData[currency] = {
              name: currency,
              data: [],
              // color: color,
              date: [],
            };
          }
          seriesData[currency].data.push(item.total);
          let date;
          if (filterType === "year") {
            date = moment()
              .month(item.month - 1)
              .format("MMMM");
          } else if (filterType === "month" || filterType === "week") {
            date = moment(item.date).format("MMM D");
          } else {
            const change = new Date(item.date);
            const options = {
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              hour12: false,
              timeZone: "UTC",
            };
            date = new Intl.DateTimeFormat("en-US", options).format(change);

            // date = moment(item.date).format('MMM DD H');
          }
          dateArray = [...new Set([...dateArray, date])];
        });
        const dataArray = Object.values(seriesData);
        setchartdata(dataArray);
        setchartDate(dateArray);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };

  const handlePairChange = (pair) => {
    setpairId(pair);
  };

  useEffect(() => {
    fetchData();
  }, [filterType, pairId]);

  useEffect(() => {
    if (piecurrency.length > 0) {
      setpairId(piecurrency[0]);
    }
  }, [piecurrency]);

  return (
    <div className="traffic_chart_flex">
      <div className="filter-buttons chart-group-button">
        <button
          className={
            filterType === "day" ? "primary_btn me-2 mb-2" : "border_btn me-2 mb-2"
          }
          onClick={() => handleFilterChange("day")}
        >
          {t("DAY")}
        </button>
        <button
          className={
            filterType === "week" ? "primary_btn me-2 mb-2" : "border_btn me-2 mb-2"
          }
          onClick={() => handleFilterChange("week")}
        >
          {t("WEEK")}
        </button>
        <button
          className={
            filterType === "month" ? "primary_btn me-2 mb-2" : "border_btn me-2 mb-2"
          }
          onClick={() => handleFilterChange("month")}
        >
          {t("MONTH")}
        </button>
        <button
          className={
            filterType === "year" ? "primary_btn me-2 mb-2" : "border_btn me-2 mb-2"
          }
          onClick={() => handleFilterChange("year")}
        >
          {t("YEAR")}
        </button>
      </div>
      <div class="row g-3 mt-1">
        <div class="col-lg-4">
          <div class="form-group">
            <Dropdown className="image_dropdown_wrapper" drop="down">
              <Dropdown.Toggle id="dropdown-basic" className="image_dropdown">
                <Image src={pairId.image} width={38} height={38} />
                {pairId.currency}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {piecurrency.map((option, i) => {
                  let symbole = `${option.currency}`;
                  return (
                    <Dropdown.Item onClick={() => handlePairChange(option)}>
                      <Image src={option.image} width={38} height={38} />{" "}
                      {symbole}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <ReactApexChart
        options={chartOptions}
        series={chartdata.map((series) => ({
          name: series.name,
          data: series.data,
        }))}
        type="area"
        height={500}
      />
    </div>
  );
}
